import { WorkflowTriggerResponse } from './WorkflowTriggerResponse';
import { ActionPlaceholderData, FormConfig, PeriodicReviewChecklistItemInstance, PeriodicReviewConfig, Progress, StepInfoResponse } from './Form';
import { ClientFormStatus } from './ClientFormStatus';
import { FormType } from './FormTypes';
import { DateRange } from './DateRange';
import { ClientFormSectionStatus } from './ClientFormSectionStatus';
import { ClientFormUserRoleValues } from './ClientFormUserRoles';
import { RiskRating } from '../utils/RiskUtils';
import { PeriodicReviewStatus } from './PeriodicReview';
import { Access } from './Access';
import { Translations } from './Translation';
import { DistributionResponse } from './Distribution';

export type ClientForm = {
  id: string;
  clientId: string;
  clientName: string;
  dueDateUtc: string | null;
  startDateUtc: string | null;
  status: ClientFormStatus;
  statusUtc: string | null;
  subtitle: string;
  totalActionsAnswered: number;
  form: FormConfig;
  clientModuleId: string;
  clientModuleSectionId: string;
  parentId: string | null;
  overallProgress: number;
  totalRequiredActions: number;
  totalRequiredAnswers: number;
  type: (typeof FormType)[keyof typeof FormType];
  totalRiskScore: number;
  majorVersion: number;
  minorVersion: number;
  isShared: boolean;
  readonly isPublic: boolean;
  modifiedUtc: string;
  sectionStatuses: ClientFormSectionInfo[];
  sectionProgress: Record<
    string,
    {
      requiredActions: number;
      requiredAnswers: number;
      requiredChildActions: number;
      requiredChildAnswers: number;
    }
  >;
  currentSteps: StepInfoResponse[];
  nextSteps: StepInfoResponse[];
  allSectionsValid: boolean;
  archivedUtc?: string;
  createdUtc: string;
  periodicReviewConfig?: PeriodicReviewConfig;
  periodicReviewChecklist?: PeriodicReviewChecklistItemInstance[];
  periodicReviewStatus?: PeriodicReviewStatus;
  periodicReviewCount: number;
  accessType: Access;
  attachments?: Attachment[];
  placeholders?: Record<string, ActionPlaceholderData>;
  originId: string | null;
  effectiveDateUtc: string | null;
  distribution?: DistributionResponse;
} & PeriodicReviewConfigForGets;

export type PeriodicReviewConfigForGets = {
  nextReviewStartDateUtc?: string;
  periodicReviewDueDate?: string;
  periodicReviewStatus?: PeriodicReviewStatus;
  lastReviewCompletedDateUtc?: string;
  periodicReviewCreatedDateUtc?: string;
  periodicReviewDueDateUtc?: string;
};

export type ClientFormSectionInfo = {
  id: string;
  templateFormSectionId: string;
  clientFormId: string;
  status: ClientFormSectionStatus;
  statusUtc: string;
  statusChangedBy: string;
  workflowTriggerResponse: WorkflowTriggerResponse;
  totalRequiredAnswers: number;
  totalRequiredActions: number;
  allSectionsValid: boolean;
  placeholders: { addedLookups: Record<string, ActionPlaceholderData>; removedLookups: Record<string, ActionPlaceholderData> };
};

export type ClientFormRequest = {
  templateFormId: string;
  templateFormVersion?: number;
  clientId: string;
  subtitle: string | null;
  clientModuleId?: string;
  clientModuleSectionId?: string;
  parentId?: string;
  parentSectionId?: string;
  parentActionId?: string;
  ownerId?: string;
  dueDateUtc?: Date | null;
  startDateUtc?: Date | null;
  allowNoOwner?: boolean;
  effectiveDateUtc?: Date | null;
};

type Paging = { pageSize?: number; pageNumber?: number; sortBy?: string };

export type ActivityFilter = {
  types?: (typeof FormType)[keyof typeof FormType][];
  isArchived?: boolean;
  picklistIds?: Record<string, string[]>;
  roles?: ClientFormUserRoleValues[];
  title?: string;
  formStatus?: ClientFormStatus[];
  riskStatuses?: RiskRating[];
  dueDateRange?: DateRange | null;
  users?: string[];
  templateIds?: string[];
  clientModuleId?: string;
  clientModuleSectionId?: string;
  templateModuleId?: string;
  templateModuleSectionId?: string;
  excludedFormIds?: string[];
  includedFormIds?: string[];
  countReferences?: FormReferenceDirection;
  countAttachments?: boolean;
};

export type DataExportActivityFilter = ActivityPagedFilter & {
  excludedAttachmentIds?: Record<string, string[]>;
  excludedReferenceIds?: Record<string, string[]>;
};

export type ActivityPagedFilter = ActivityFilter & Paging;

export enum FormReferenceDirection {
  Source = 0,
  Target = 1,
}

export type ClientFormProgressResponse = {
  form?: number;
  formOverall: number;
  totalRequiredActions: number;
  totalRequiredAnswers: number;
  sectionProgress: Record<string, Progress>;
};

export type Attachment = {
  fileName: string;
  fileId: string;
  fileFormat: string;
  formActionId?: string;
  formAnswerId?: string;
  formSectionId?: string;
};

export type SignatureDetails = {
  signerName: string;
  password: string;
  twoFactor: string;
};

export type ClientFormTitleResponse = {
  id: string;
  subtitle: string;
  documentNumber: number | null;
  templateModuleTranslations: Translations;
};
